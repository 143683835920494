import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";
import FormFields from "layouts/pages/account/components/FormField";
import selectData from "./data";
import MDButton from "components/MDButton";
import { format } from "date-fns";

//Modal
import Modal from "./Modal";

//Hooks
import { useKingsooService } from "../../../../hooks/useKingsooServices";
import { useCollection } from "hooks/useCollection";

const AirwayBillForm = () => {
  const [fileId, setFileId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [consigneeId, setConsigneeId] = useState("");
  const [airline, setAirline] = useState("");
  const [freightType, setFreightType] = useState("");
  const [jobManagerId, setJobManagerId] = useState("");
  const [packages, setPackages] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [descriptionOfGoods, setDescriptionOfGoods] = useState("");
  const [reset, setReset] = useState("");
  const [preAlertstatus] = useState("IN PROGRESS");
  const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [jobCreatedDate] = useState(
    format(new Date(), "MM/dd/yyyy 'at' h:mm a")
  );
  const [jobId, setJobId] = useState("");

  let navigate = useNavigate();

  const { id } = useParams();
  const { documents } = useCollection("clients");
  const { documents: datas } = useCollection("consignee");
  const { documents: jobs } = useCollection("jobManager");
  const { documents: cd } = useCollection("jobs");
  const { airWayBill, getJob } = useKingsooService();

  const airwaybill = async (e) => {
    e.preventDefault();

    try {
      await airWayBill(id, {
        fileId,
        companyId: companyId || null,
        jobManagerId: jobManagerId || null,
        consigneeId: consigneeId || null,
        airline: airline || null,
        freightType: freightType || null,
        packages: packages || null,
        totalWeight: totalWeight || null,
        expectedTimeOfArrival: expectedTimeOfArrival || null,
        descriptionOfGoods: descriptionOfGoods || null,
        preAlertstatus,
        jobCreatedDate,
      });
      setReset(true);
      if (reset) {
        e.target.reset();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  if (reset) {
    navigate("/monitoring-compliance/job-management/pending-job-files", {
      replace: true,
    });
  }

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await getJob(id);
        // console.log(docSnap.data())
        setCompanyId(docSnap.data().companyId);
        setConsigneeId(docSnap.data().consigneeId);
        setFreightType(docSnap.data().freightType);
        setJobManagerId(docSnap.data().jobManagerId);
        setExpectedTimeOfArrival(docSnap.data().expectedTimeOfArrival);
      } catch {
        console.log("error");
      }
    };
    // console.log('na u know', id)
    if ((id !== undefined || id !== "") && id !== ":id") {
      detail();
    }
  }, [id]);

  const date = format(new Date(), "yyyyMMdd");
  const code = cd.filter(
    (data) =>
      data.preAlertstatus !== "PENDING" && data.preAlertstatus !== "TERMINATED"
    // data.jobType !== 'Export'
  );
  const payment = code.map(function (cd) {
    return parseInt(cd?.fileId?.split("/")[2]);
  });

  const values = Object.values(payment);
  // const sliced = parsed.slice(-4)
  let biggest = values[0];

  for (var i = 0; i < values.length; i++) {
    if (biggest < values[i]) {
      biggest = values[i];
    }
  }

  //code for updated
  //   let currentYear = 2023;
  // console.log(currentYear)
  // for (var i = 0; i < values.length; i++) {
  //   const fileIdYear = parseInt(code[i].fileId?.slice(4, 8));
  //   if (fileIdYear === currentYear && biggest < values[i]) {
  //     biggest = values[i];
  //   } else {
  //     biggest = 0
  //   }
  // }

  //   let nextNumber = biggest + 1;
  //   nextNumber = nextNumber.toString().padStart(2, '0');
  //   console.log(nextNumber)

  useEffect(() => {
    if (cd) {
      setFileId(`KGM/${date}/${biggest + 1}`);
    }
  }, [cd, date, code]);

  const getJobId = (id) => {
    // console.log('the id', id)
    setJobId(id);
  };
  //  console.log(fileId, cd)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {cd.length > 0 ? (
        <div className="mt-4">
          <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
            <Box component="form" onSubmit={airwaybill}>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ borderBottom: 1, pb: 1, pt: 4 }}
              >
                Airway Bill Form
              </Typography>
              <Box mt={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Select Client
                    </Typography>
                    <Autocomplete
                      id="import-type"
                      options={documents.map((document) => document.clientName)}
                      onChange={(e, newValue) => {
                        setCompanyId(newValue);
                      }}
                      value={companyId}
                      renderInput={(params) => (
                        <FormFields
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Select Consignee
                    </Typography>
                    <Autocomplete
                      id="import-type"
                      options={datas.map((data) => data.consigneeName)}
                      onChange={(e, newValue) => {
                        setConsigneeId(newValue);
                      }}
                      value={consigneeId}
                      renderInput={(params) => (
                        <FormFields
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Airline
                    </Typography>
                    <TextField
                      id="airline"
                      fullWidth
                      onChange={(e) => setAirline(e.target.value)}
                      value={airline}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Type of Freight
                    </Typography>
                    <Autocomplete
                      id="type-of-freight"
                      options={selectData.freight}
                      onChange={(e, newValue) => setFreightType(newValue)}
                      value={freightType}
                      renderInput={(params) => (
                        <FormFields
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Select Job Manager
                    </Typography>
                    <Autocomplete
                      id="job-manager"
                      options={jobs.map((job) => job.name)}
                      onChange={(e, newValue) => {
                        setJobManagerId(newValue);
                      }}
                      value={jobManagerId}
                      renderInput={(params) => (
                        <FormFields
                          {...params}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      No of packages
                    </Typography>
                    <TextField
                      id="no-of-packages"
                      fullWidth
                      onChange={(e) => setPackages(e.target.value)}
                      value={packages}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Total weight of package (kg)
                    </Typography>
                    <TextField
                      id="total-weight"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setTotalWeight(e.target.value)}
                      value={totalWeight}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography fontSize={16} marginBottom={1}>
                      Expected Time of Arrival (ETA)
                    </Typography>
                    <div className="picker">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          className="birth"
                          value={expectedTimeOfArrival}
                          onChange={(newValue) => {
                            setExpectedTimeOfArrival(
                              format(new Date(newValue), "yyyy-MM-dd")
                            );
                          }}
                          fullWidth
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontSize={16} marginBottom={1}>
                      Description of Goods
                    </Typography>
                    <TextField
                      id="description-of-goods"
                      fullWidth
                      onChange={(e) => setDescriptionOfGoods(e.target.value)}
                      value={descriptionOfGoods}
                      multiline
                      rows={6}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  pb: 1,
                  pt: 4,
                  pl: 3,
                  pr: 3,
                  mt: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "2rem",
                }}
              >
                <MDButton variant="gradient" color="dark" type="submit">
                  Create
                </MDButton>
              </Box>
            </Box>
            <button
              className="absolute bg-transparent mr-2 right-30 bottom-20"
              onClick={(e) => getJobId(id)}
            >
              <Modal id={jobId} setJobId={setJobId} />
            </button>
          </Paper>
        </div>
      ) : (
        <div className="mt-4">
          <Paper sx={{ mt: 4, pl: 3, pr: 3, pb: 6, boxShadow: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ pb: 1, pt: 4 }}>
              Loading...
            </Typography>
          </Paper>
        </div>
      )}
    </DashboardLayout>
  );
};

export default AirwayBillForm;
